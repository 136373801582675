let instance;

export default class SectionManager {

  /**
   * Singleton class, will return the same instance after it creates one
   */
  constructor() {
    if (instance) {
      return instance;
    }
    instance = this;
    this.initialize();
  }

  initialize() {
    /**
     * Maps css classes to ES6 classes.
     * - ES6 classes representing sections subscribe to the singleton 'instance'
     * - the ES6 classes are instanced by .initializeSections
     * - css classes are used to look for the DOM element representing the section
     * @type {Object}
     */
    this.sections = {};
  }

  /**
   * Initializes all sections that subscribed to being initialized using .subscribe()
   *
   * Looks for sections in 'root', matching the css classes subscribed, and instances
   * them with the respective classes classes.
   *
   * @param  {string} root - css selector, subscribed sections will be searched for and
   *                       initialized within its children
   */
  initializeSections( root ) {
    for (const [cssClass, sectionClass] of Object.entries(this.sections)) {
      // Search for targets with cssClass in child notes, and check if self has it, too
      let $targets = $(root).find(cssClass).addBack(cssClass);
      $targets.each(function( index ) {
        new sectionClass(this);
      });
    }
  }

  /**
   * Subscribe a new section to be initialized by .initializeSectiomns
   * @param  {string}   css_class    A selector for the DOM, corresponding to the root element
   *                                 of a section
   * @param  {Function} sectionClass A class passed as a function argument
   */
  subscribe(css_class, sectionClass) {
    this.sections[css_class] = sectionClass;
  }
}

/**
 * On document ready, initialize all the sections subscribed, using body as a root
 * This is the entry point where all section classes get instanced after the page is rendered.
 *
 * NOTE: this initialization happens again once a Section is reloaded, this allows the new DOM
 * elements to reinitialize using new event handlers, plugins etc.
 */
$(function () {
  let manager = new SectionManager();
  manager.initializeSections('body');
});
