import { subscribe as subscribeOnscreenKeyboard } from 'on-screen-keyboard-detector';

import SearchEngine from '../components/search-engine';

import SectionManager from './section-manager';

export default class SearchEngineSection {
  constructor( element ) {
    this.element = element;
    this.initialize();
  }

  initialize() {
    new SearchEngine(this.element);

    // On focusing the zone field, if an onscreen 'soft' keyboard is present, scroll the form into view
    $(this.element).find('.location-select input.picker').on('focus', (event) => {
      let heroFormTopOffset = $(this.element).position().top;
      const unsubscribe = subscribeOnscreenKeyboard(visibility => {
        if ( visibility === "visible" ) {
          let bodyPadding = $("body").outerHeight() - $("body").height(),
              currentScrollTop = $("body").scrollTop();
          // Only scroll to if the browser hasn't already scrolled lower than we intend to
          if (currentScrollTop < (heroFormTopOffset - bodyPadding)) {
            $("body").scrollTop(heroFormTopOffset - bodyPadding);
          }
          unsubscribe();
        }
      });
    });
  }
}

let manager = new SectionManager();
manager.subscribe(".search-engine-form", SearchEngineSection);
